import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";

let config = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: "sust-dev-03.firebaseapp.com",
    projectId: "sust-dev-03",
};

const tenantId = process.env.REACT_APP_FIREBASE_TENANT
export const useLocalLogin = !!process.env.REACT_APP_USE_LOCAL_LOGIN
export const ce_default_url = process.env.REACT_APP_EXPLORER_URL
console.log(`[FIREBASE] Tenant ID: ${tenantId}`)

const app = initializeApp(config);
export const auth = getAuth(app);
auth.tenantId = tenantId