import React, { useState, useEffect } from "react";
import "./TemperatureScenarioSelector.css";

const TemperatureScenarioSelector = (props) => {
  const { temperatureScenarios,
          selectedTemperatureScenario,
          setSelectedTemperatureScenario
         } = props;

  const tempScenarioMap = {
    "SSP2-RCP4.5 : 2.4C": "2.4°C",
    "SSP5-RCP8.5 : 4.3C": "4.3°C",
  };

  const handleScenarioChange = (e) => {
    setSelectedTemperatureScenario(tempScenarioMap[e]);
  }
  
  return (
    <div className="temperature-selector-container">
      <div className="temperature-selector">
        {temperatureScenarios.map((temp) => (
          <div
            key={temp}
            className={`temperature-option ${
              selectedTemperatureScenario === tempScenarioMap[temp] ? "selected" : ""
            }`}
            onClick={() => handleScenarioChange(temp)}
          >
            {temp}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TemperatureScenarioSelector;