import {signOut} from 'firebase/auth';
import {auth, ce_default_url, useLocalLogin} from "../../context/firebase-config";

const Logout = () => {
    const isLocalLogin = useLocalLogin;
    const ceDefaultLogout = ce_default_url.concat('/account/logout/?next=/')

    signOut(auth)
        .then(() => {
            console.log(`[LOGOUT] Redirect URL: ${ceDefaultLogout}`);
            if (isLocalLogin) {
                window.location.reload();
            } else {
                window.location.replace(ceDefaultLogout);
            }
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(`[SIGN_OUT] error ${errorCode} - ${errorMessage}`)
        });
}

export default Logout