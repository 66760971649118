import React, {useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {FaExternalLinkAlt, FaInfoCircle, FaUserCircle} from "react-icons/fa";
import SustLogo from '../../data/images/sust-HexLogoONLYwhite.png';
import './TopNavBar.css';
import {authContext} from "../../context/authContext";
import {ce_default_url} from '../../context/firebase-config'

const TopNavBar = () => {
    const user = useContext(authContext);
    const [superUser, setSuperUser] = useState(false);
    const [activeTab, setActiveTab] = useState('index'); // default active tab
    let apps = AppsToShow()
    let projects = ProjectsToShow()

    useEffect(() => {
        if (user) {
            user.getIdTokenResult()
                .then((token) => {
                    if (!!token.claims.is_superuser) {
                        setSuperUser(token.claims.is_superuser)
                    } else {
                        console.error('superuser status not available for user')
                    }
                })
                .catch((error) => {
                    console.error(`error while retrieving superuser status: ${error}`)
                });
        }
    }, [user])

    return (
        <Navbar expand="sm" className="bg-dark" data-bs-theme="dark"
                style={{height: '70px', backgroundColor: '#212527'}}>
            <Container style={{background: '#212527', width: '1275px'}}>
                <Navbar.Brand className='navbar-brand'>
                    <img
                        alt="SUST LOGO"
                        src={SustLogo}
                        width="8.2%"
                        height="auto"
                    />{' '}
                    <span style={{
                        fontSize: '28px',
                        fontWeight: '400',
                        verticalAlign: 'middle',
                        color: '#ffffff',
                    }}>
                        Climate Explorer
                        </span>
                </Navbar.Brand>
                {/*<Navbar.Toggle aria-controls="basic-navbar-nav"/>*/}
                <Navbar.Collapse id="basic-navbar-nav">
                    {useMenu(user, superUser, apps, projects)}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

const ProjectsToShow = (project, setProject) => {
    return ['Project 1', 'Project 2', 'Project 3'].map(
        (p, index) => {
            return (
                <Dropdown.Item onClick={() => setProject(p)} style={{width: '217px'}}>{p}</Dropdown.Item>
            )
        }
    )
}

const AppsToShow = () => {
    const user = useContext(authContext);
    const [apps, setApps] = useState([])

    useEffect(() => {
        user && user.getIdTokenResult()
            .then((token) => {
                if (!!token.claims.api_key) {
                    fetch(`${ce_default_url}/api/myapps`, {
                        method: 'GET',
                        headers: {
                            'X-SustGlobal-APIKey': token.claims.api_key,
                            'X-SustGlobal-Project': 'sust-ptg',
                        }
                    })
                        .then(r => r.json())
                        .then(r => setApps(r))
                } else {
                    console.error('api key not available for user')
                }
            })
            .catch((error) => {
                console.error(`[APPS_LIST] Error while fetching app list: ${error}`)
            })
    }, [user])

    if (apps.length > 0) {
        return apps.map(
            (app, index) => {
                return (
                    <Dropdown.Item href={app.url} disabled={!app.has_access}
                                   style={{width: '218px'}}>{app.name}</Dropdown.Item>
                )
            }
        )
    }
}

const useMenu = (user, superUser, apps, projects) => {
    if (user) {
        const UserDropdownTitle = (<FaUserCircle size={25}/>);
        const infoDropdownTitle = (<FaInfoCircle size={25}/>);
        const externalLink = (<FaExternalLinkAlt size={15}/>);
        return (
            <>
                <Nav className="me-2" style={{
                    border: '1px solid #505050',
                    borderRadius: '4px',
                    width: '220px',
                    height: '38px',
                    lineHeight: '1.5'
                }}>
                    <Dropdown as={ButtonGroup}>
                        <Button style={{
                            fontWeight: '500',
                            backgroundColor: '#212529',
                            border: '0px',
                            borderRadius: '4px',
                            color: '#ffffff8c'
                        }}>Apps</Button>
                        <Dropdown.Toggle split style={{
                            textAlign: 'right',
                            backgroundColor: '#212529',
                            border: '0px',
                            width: '153px',
                            color: '#ffffff8c'
                        }}/>
                        <Dropdown.Menu style={{backgroundColor: '#212529', textAlign: 'left'}}>
                            {apps}
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>

                <Nav className="me-2" style={{
                    border: '1px solid #505050',
                    borderRadius: '4px',
                    width: '220px',
                    height: '38px',
                    lineHeight: '1.5'
                }}>
                    <Dropdown as={ButtonGroup}>
                        <Button style={{
                            fontWeight: '500',
                            backgroundColor: '#212529',
                            border: '0px',
                            borderRadius: '4px',
                            color: '#ffffff8c'
                        }}>Projects</Button>
                        <Dropdown.Toggle split style={{
                            textAlign: 'right',
                            backgroundColor: '#212529',
                            border: '0px',
                            width: '133px',
                            color: '#ffffff8c'
                        }}/>
                        <Dropdown.Menu style={{backgroundColor: '#212529', textAlign: 'left'}}>
                            {projects}
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>

                <Nav className="me-0">
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle
                            style={{textAlign: 'right', backgroundColor: '#212529', color: '#ffffff8c', border: '0px'}}>
                            {infoDropdownTitle}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{backgroundColor: '#212529', textAlign: 'left'}}>
                            <Dropdown.Item href="https://developers.sustglobal.com/user-guide" target={"_blank"}
                                           style={{textDecoration: 'none'}}>
                                {" Climate Explorer User Guide "}
                                {externalLink}
                            </Dropdown.Item>
                            <Dropdown.Item href="https://developers.sustglobal.com/" target={"_blank"}>
                                {' Sust Global Developer Center '}
                                {externalLink}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>

                <Nav className="me-0">
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle
                            style={{textAlign: 'right', backgroundColor: '#212529', color: '#ffffff8c', border: '0px'}}>
                            {UserDropdownTitle}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{backgroundColor: '#212529', textAlign: 'left'}}>
                            <Dropdown.Item href="/account/profile">User Profile</Dropdown.Item>
                            <Dropdown.Item href="/logout">Log Out</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>
            </>
        )
    } else {
        return (<Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href={'#'}>Login</Nav.Link>
            </Nav>
        </Navbar.Collapse>)
    }
}

export default TopNavBar;
